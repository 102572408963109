@import "src/styles/mixins";

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 800px;
  height: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include box_layout;
  &_header {
    width: 100%;
  }
  &_body {
    width: 100%;

  }
  &_footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;

  }

}

.button_cancel {
  margin-left: 20px;
}