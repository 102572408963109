@import "src/styles/mixins";

.list {
  @include box_layout;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 18px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}