@import "src/styles/mixins";


.item {
  @include box_layout;
  width: 100%;
  min-height: min-content;
  flex-grow: 1;
  border-color: rgba(49, 49, 227, 0.37);
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  padding: 0;
  &:last-child {
    margin-bottom: 0;
  }

  &__button {
    border-radius: 8px;
    padding: 5px 8px;
    background-color: rgba(54, 192, 6, 0.94);
    color: white;
    font-family: 'Roboto', sans-serif;
  }

  &__header{
    display: flex;
    width: 100%;
    padding: 5px 20px;
    justify-content: space-between;
    align-items: center;
    &__content {
      display: flex;
      align-items: center;
      & span {
        &:first-child {
          padding: 5px 8px;
          border-radius: 8px;
          background-color: rgba(43, 156, 226, 0.85);
          color: white;
          margin-right: 10px;
          text-align: center;
        }
        &:last-child {
          font-family: 'Roboto', sans-serif;
          font-weight: 600;
        }
      }
    }
  }
  &_body {
    width: 100%;
    display: flex;
    justify-content: start;
    border-top: 1px solid rgba(169, 169, 169, 0.51);
  }
  &_content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 5px 20px;
  }
}