.wrapper {
  display: flex;
  height: 30px;
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid rgba(98, 98, 98, 0.2);
  align-items: center;
  &:hover {
    background-color: rgba(98, 98, 98, 0.2);
    cursor: pointer;
  }
}