.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  &_form {
    height: 90%;
    width: 90%;
    display: flex;
    justify-content: center;

    &_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 30%;

      &__img {
        margin-top: 28px;
        object-fit: contain;
        height: 20vh;
      }

      &__text {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        line-height: 52px;
      }
    }
  }
}

.btn {
  padding: 0;
  height: 50px;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  box-shadow: 0px 4px 20px rgba(92, 62, 198, 0.4);
  border-radius: 15px;
  background-color: grey;

  &:active {
    position: relative;
    top: 1px;
  }
}

.textInput {
  margin-bottom: 20px;
  height: 20px;
  font-size: 16px;
}

.background {
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: 100%;
  bottom: -70px;
}
