.wrapper {
  height: 100%;
  &_tabs {
    margin-bottom: 20px;
  }
  &_keywords {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}


.wrapper_new {
  display: flex;
  height: 30px;
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid rgba(98, 98, 98, 0.2);
  align-items: center;
  &:hover {
    background-color: rgba(98, 98, 98, 0.2);
    cursor: pointer;
  }
}

.input {
  border-radius: 6px;
  border: 1px solid rgba(98, 98, 98, 0.2);
  width: 100%;
  height: 35px;
  font-size: 16px;
  padding-left: 10px;
}