@import "/src/styles/mixins";

.wrapper {
  @include box_layout;
  margin-left: 50px;
}


.table_body {
  overflow-x: scroll;
  scroll-behavior: smooth;
}

