@import "src/styles/mixins";

.wrapper {
  display: flex;
  padding-left: 70px;
  justify-content: space-around;
  flex-direction: column;

  &_data {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 2fr;
    gap: 15px;
    align-items: end;
    &:nth-child(2) {
      margin-top: 40px;
    }
    :nth-child(4n) {

      margin: 0 auto;
    }
    &_title {
      color: grey;
      margin-bottom: 10px;
    }
    &_tile {
      @include box_layout;
      min-height: 120px;
      min-width: 180px;
      display: flex;
      &_img {
        max-width: 70px;
        min-width: 50px;
        max-height: 80px;
      }
      &_info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 5px;
      }
    }
  }
}
