.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  &_header {
    padding-top: 20px;
    font-size: 35px;
  }
}

.link {
  &_list {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px 10px 20px;
  }
  &_item {
    padding: 8px;


    &:hover {
      background-color: rgba(128, 128, 128, 0.13);
    }
    margin-top: 20px;
    border-radius: 8px;
    &:nth-child(1) {
      margin-top: 0;
    }
    &_active{
      background-color: rgba(128, 128, 128, 0.13);
    }
    &_img {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 35px;
      height: 35px;
    }
  }
}

.manual {
  padding: 8px;
  margin-top: 20px;
  border-radius: 8px;

  &:hover {
    background-color: rgba(128, 128, 128, 0.13);
  }
}