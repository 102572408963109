.wrapper {
  height: 100%;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 3fr 1fr;
  &_left {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    &_controls {
      grid-row-start: 2;
      grid-row-end: 10;
      grid-column-start: 1;
      grid-column-end: 5;
      width: 100%;
      height: 100%;
    }
  }
  &_right {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &_item {

    }
  }
}
