.question {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: start;
  align-items: start;
  padding: 5px 10px;
  border-top: 2px dashed rgba(255, 99, 39, 0.56);
  &:first-child {
    border-top: 0;
  }

  &__wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: end;
    border-bottom: 1px solid rgb(62, 83, 134);
    padding-bottom: 5px;
    &:last-child {
      border-bottom: none;
    }
  }

  &__edit {
    display: flex;
    &__button {
      margin-top: 10px;
      border-radius: 8px;
      padding: 5px 8px;
      background-color: rgb(62, 83, 134);
      color: white;
      font-family: 'Roboto', sans-serif;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    & span {
      margin-bottom: 10px;
    }
  }
}