.wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 2fr) 1fr;
  gap: 40px;
  padding: 40px;
  &_item {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__title {
      font-weight: bold;
    }
    &_content {
      &__title {
        font-weight: bold;
      }
      &__info {
        color: grey;
      }
    }
    &_action {
      color: cornflowerblue;
      display: flex;
      justify-content: space-between;
      border-top: 2px solid grey;
    }
  }
}
