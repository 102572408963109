@import "src/styles/mixins";

.wrapper {
  margin-left: 85px;
  padding: 20px 20px 20px 0;
  height: 100vh;
  &_statistic {
    @include box_layout;
    width: 100%;
    height: fit-content;
  }
}
