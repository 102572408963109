* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-tabs__tab--selected {
  background-color: rgba(0, 0, 0, 0.35);
}


.app_layout {
  display: flex;
  justify-content: space-between;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  &_child {
    padding: 40px 30px 20px 0;
    flex-grow: 1;
    max-height: 100vh;
    display: flex;
  }
}