.progress_bar {


  &__container {
    display: flex;
    flex-direction: column;
  }


  &__header {
    font-weight: bold;

  }

  &__body {

  }

  &__percent {

  }

  &__line {
    width: 100%;
    height: 10px;
    border: 1px solid grey;
    border-radius: 20px;
  }
}