@import "src/styles/mixins";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-left: 80px;
  &_header {
    font-size: 1.3rem;
    color: rgba(105, 105, 105, 0.76);
  }
  &_statistic {
    width: 100%;

    max-height: 90%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    @include box_layout;
  }
}
.post_text {
  word-break: break-all;
}

ul {
  display: flex;
  flex-direction: column;
}