.primary {
  padding: 10px 20px;
  background-color: rgba(138, 232, 193, 0.87);
  color: #FFF;
  border-radius: 6px;
  border: none;
  &:hover {
    transform: scale(1.05);
    transition: 150ms;
}
}

.secondary {
  padding: 10px 20px;
  background-color: rgba(190, 232, 138, 0.72);
  color: #FFF;
  border-radius: 6px;
  border: none;
  &:hover {
    transform: scale(1.05);
    transition: 150ms;
  }
}